import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import AccountBox from './AccountBox';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import MultiRangeSlider from "multi-range-slider-react";
import WorkspaceMemberItem from './WorkspaceMemberItem';
import { AiOutlinePlus } from "react-icons/ai";
import axios from 'axios';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../context/userContext';

export default function SettingsContent() {
    const [inmailValue, setInmailValue] = useState(0);

    const [minValueLimit1, setMinValueLimit1] = useState(10);
    const [maxValueLimit1, setMaxValueLimit1] = useState(15);
    const handleInputLimit1 = (e) => {
        setMinValueLimit1(e.minValue);
        setMaxValueLimit1(e.maxValue);
    };

    const [minValueLimit2, setMinValueLimit2] = useState(40);
    const [maxValueLimit2, setMaxValueLimit2] = useState(50);
    const handleInputLimit2 = (e) => {
        setMinValueLimit2(e.minValue);
        setMaxValueLimit2(e.maxValue);
    };

    const [minValueLimit3, setMinValueLimit3] = useState(10);
    const [maxValueLimit3, setMaxValueLimit3] = useState(15);
    const handleInputLimit3 = (e) => {
        setMinValueLimit3(e.minValue);
        setMaxValueLimit3(e.maxValue);
    };

    const [minValueLimit4, setMinValueLimit4] = useState(25);
    const [maxValueLimit4, setMaxValueLimit4] = useState(35);
    const handleInputLimit4 = (e) => {
        setMinValueLimit4(e.minValue);
        setMaxValueLimit4(e.maxValue);
    };

    const [minValueLimit5, setMinValueLimit5] = useState(5);
    const [maxValueLimit5, setMaxValueLimit5] = useState(10);
    const handleInputLimit5 = (e) => {
        setMinValueLimit5(e.minValue);
        setMaxValueLimit5(e.maxValue);
    };

    const [minValueLimit6, setMinValueLimit6] = useState(25);
    const [maxValueLimit6, setMaxValueLimit6] = useState(30);
    const handleInputLimit6 = (e) => {
        setMinValueLimit6(e.minValue);
        setMaxValueLimit6(e.maxValue);
    };

    const [minValueLimit7, setMinValueLimit7] = useState(90);
    const [maxValueLimit7, setMaxValueLimit7] = useState(100);
    const handleInputLimit7 = (e) => {
        setMinValueLimit7(e.minValue);
        setMaxValueLimit7(e.maxValue);
    };

    const accounts = [
        // {
        //     id: '1',
        //     image: '/images/testimonee.jpg',
        //     status: 'connected',
        //     email: 'milli.bob@gmail.com',
        // },
        // {
        //     id: '2',
        //     image: '/images/testimonee.jpg',
        //     status: 'paused',
        //     email: 'milli.bob@gmail.com',
        // },
        // {
        //     id: '3',
        //     image: '/images/testimonee.jpg',
        //     status: 'disconnected',
        //     email: 'milli.bob@gmail.com',
        // },
        // {
        //     id: '4',
        //     image: '/images/testimonee.jpg',
        //     status: 'paused',
        //     email: 'milli.bob@gmail.com',
        // },
        // {
        //     id: '5',
        //     image: '/images/testimonee.jpg',
        //     status: 'paused',
        //     email: 'milli.bob@gmail.com',
        // }
    ];

    const workspaceMembers = [
        {
            id: '1',
            email: 'milli.bob@gmail.com',
            accounts: [
                {
                    id: '1',
                    image: '/images/testimonee.jpg',
                    email: 'chris.evan1@gmail.com'
                },
                {
                    id: '2',
                    image: '/images/testimonee.jpg',
                    email: 'chris.evan2@gmail.com'
                }
            ],
            adminRight: true
        },
        {
            id: '2',
            email: 'milli.bob@gmail.com',
            accounts: [
                {
                    id: '1',
                    image: '/images/testimonee.jpg',
                    email: 'chris.evan3@gmail.com'
                },
                {
                    id: '2',
                    image: '/images/testimonee.jpg',
                    email: 'chris.evan4@gmail.com'
                }
            ],
            adminRight: false
        },
        {
            id: '3',
            email: 'milli.bob@gmail.com',
            accounts: [],
            adminRight: false
        }
    ];

    const workspaceName = 'Alpha Team';
    const inviteCode = 'fh39988jdsss0'

    const { getLinkedinAccountList, linkedinAccountList } = useContext(UserContext);

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(!show);

    const [formValues, setFormValues] = useState({
        'linkedin_email': '',
        'linkedin_password': '',
        'custom_proxy_ip': '',
        'custom_port': '',
        'custom_proxy_username': '',
        'custom_proxy_password': '',
        'is_custom_proxy': false,
    })


    const linkedInLogin = async (e) => {
        e.preventDefault();
        const id = toast.loading("Please wait...");
        const storedToken = JSON.parse(localStorage.getItem('accessToken'));
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_BASE_URL}/api/linkedinAccount/`, formValues, {
                headers: {
                    'Authorization': `Bearer ${storedToken}`
                }
            })
            console.log(response);
            toast.success('LinkedIn Account Added Successfully', {
                id: id,
            });
            getLinkedinAccountList();
        } catch (error) {
            toast.error("Something went wrong", {
                id: id,
            });
        }
    }

    console.log("formValues : ", formValues);

    useEffect(() => {
        getLinkedinAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='settings-content secondary-bg p-3'>
            <div className='row g-0'>
                <div className='col-md-12'>
                    <div className='section pb-4'>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className='mb-0'>LinkedIn Accounts</h5>
                            <Button variant='primary d-flex align-items-center' onClick={handleShow}>
                                <AiOutlinePlus size={18} className='me-0 me-sm-1' />
                                <span className='d-none d-sm-inline'>New Linkedin</span>
                            </Button>
                        </div>
                        <div className='d-flex flex-wrap'>
                            {
                                linkedinAccountList.length > 0 ?
                                    linkedinAccountList.map((item) => {
                                        return <AccountBox key={item.id} data={item} />
                                    })
                                    : <p>No LinkedIn Account Connected</p>
                                // accounts.map((item) => {
                                //     return <AccountBox key={item.id} data={item} />
                                // })
                            }
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className='mb-0'>Email Accounts</h5>
                            <Button variant='primary d-flex align-items-center'>
                                <AiOutlinePlus size={18} className='me-0 me-sm-1' />
                                <span className='d-none d-sm-inline'>New Email</span>
                            </Button>
                        </div>
                        <div className='d-flex flex-wrap'>
                            {
                                accounts.length > 0 ?
                                    accounts.map((item) => {
                                        return <AccountBox key={item.id} data={item} />
                                    })
                                    : <p>No Email Account Connected</p>
                            }
                        </div>
                    </div>
                    <div className='section py-4'>
                        <div className='d-flex justify-content-between'>
                            <h5 className='mb-3'>Outreach Limits</h5>
                            <Form.Switch
                                id='default-limits'
                                label='Set as Default'
                            />
                        </div>
                        <div className='d-flex align-items-center'>
                            <Form.Switch
                                id="weekend-run-switch"
                                label="Run on weekend"
                                checked
                            />
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <Form.Switch
                                id="connect-req-boost-switch"
                                label="Use connection requests booster"
                            />
                        </div>
                        <div className='row g-0'>
                            <div className='col-md-6 pe-0 pe-md-4'>
                                <div className='d-flex justify-content-between mt-3'>
                                    <p>Views of profiles per day</p>
                                    <p>{minValueLimit1} - {maxValueLimit1}</p>
                                </div>
                                <MultiRangeSlider
                                    min={0}
                                    max={100}
                                    step={1}
                                    ruler={false}
                                    label={false}
                                    preventWheel={false}
                                    minValue={minValueLimit1}
                                    maxValue={maxValueLimit1}
                                    onInput={(e) => {
                                        handleInputLimit1(e);
                                    }}
                                />
                                <div className='d-flex justify-content-between mt-3'>
                                    <p>Connections by email per day</p>
                                    <p>{minValueLimit2} - {maxValueLimit2}</p>
                                </div>
                                <MultiRangeSlider
                                    min={0}
                                    max={100}
                                    step={1}
                                    ruler={false}
                                    label={false}
                                    preventWheel={false}
                                    minValue={minValueLimit2}
                                    maxValue={maxValueLimit2}
                                    onInput={(e) => {
                                        handleInputLimit2(e);
                                    }}
                                />
                                <div className='d-flex justify-content-between mt-3'>
                                    <p>Connection requests per day</p>
                                    <p>{minValueLimit3} - {maxValueLimit3}</p>
                                </div>
                                <MultiRangeSlider
                                    min={0}
                                    max={80}
                                    step={1}
                                    ruler={false}
                                    label={false}
                                    preventWheel={false}
                                    minValue={minValueLimit3}
                                    maxValue={maxValueLimit3}
                                    onInput={(e) => {
                                        handleInputLimit3(e);
                                    }}
                                />
                                <div className='d-flex justify-content-between mt-3'>
                                    <p>Messages per day</p>
                                    <p>{minValueLimit4} - {maxValueLimit4}</p>
                                </div>
                                <MultiRangeSlider
                                    min={0}
                                    max={80}
                                    step={1}
                                    ruler={false}
                                    label={false}
                                    preventWheel={false}
                                    minValue={minValueLimit4}
                                    maxValue={maxValueLimit4}
                                    onInput={(e) => {
                                        handleInputLimit4(e);
                                    }}
                                />
                            </div>
                            <div className='col-md-6 ps-0 ps-md-4'>
                                <div className='d-flex justify-content-between mt-3'>
                                    <p>InMails per day</p>
                                    <p>{minValueLimit5} - {maxValueLimit5}</p>
                                </div>
                                <MultiRangeSlider
                                    min={0}
                                    max={80}
                                    step={1}
                                    ruler={false}
                                    label={false}
                                    preventWheel={false}
                                    minValue={minValueLimit5}
                                    maxValue={maxValueLimit5}
                                    onInput={(e) => {
                                        handleInputLimit5(e);
                                    }}
                                />
                                <div className='d-flex justify-content-between mt-3'>
                                    <p>Searches per day</p>
                                    <p>{minValueLimit6} - {maxValueLimit6}</p>
                                </div>
                                <MultiRangeSlider
                                    min={0}
                                    max={60}
                                    step={1}
                                    ruler={false}
                                    label={false}
                                    preventWheel={false}
                                    minValue={minValueLimit6}
                                    maxValue={maxValueLimit6}
                                    onInput={(e) => {
                                        handleInputLimit6(e);
                                    }}
                                />
                                <div className='d-flex justify-content-between mt-3'>
                                    <p>Emails per day</p>
                                    <p>{minValueLimit7} - {maxValueLimit7}</p>
                                </div>
                                <MultiRangeSlider
                                    min={0}
                                    max={250}
                                    step={1}
                                    ruler={false}
                                    label={false}
                                    preventWheel={false}
                                    minValue={minValueLimit7}
                                    maxValue={maxValueLimit7}
                                    onInput={(e) => {
                                        handleInputLimit7(e);
                                    }}
                                />
                                <p className='inmail-title mt-5'>For LinkedIn Premium Accounts Only</p>
                                <div className='d-flex justify-content-between'>
                                    <p>InMail credits per month</p>
                                    <p>{inmailValue}</p>
                                </div>
                                <RangeSlider
                                    tooltip='off'
                                    variant='primary'
                                    value={inmailValue}
                                    onChange={e => setInmailValue(e.target.value)}
                                />
                                <p className='credits-info mb-0'>Inmail credits left: <span>0</span></p>
                                <p className='credits-info mb-0'>Inmail credits used this month <span>0</span></p>
                            </div>
                        </div>
                    </div>
                    <div className='section input-style py-4'>
                        <h5 className='mb-3'>Preferences</h5>
                        <div className='sub-section'>
                            <div className='d-flex align-items-center section-divider mb-3'>
                                <p className='mb-0 pe-3'>Menu Preferences</p>
                                <hr />
                            </div>
                            <div className='text-end'>
                                <Button variant='primary' className='mb-2'>Add <AiOutlinePlus size={18} /></Button>
                            </div>
                        </div>
                        <div className='sub-section'>
                            <div className='d-flex align-items-center section-divider mb-3'>
                                <p className='mb-0 pe-3'>Workspace Preferences</p>
                                <hr />
                            </div>
                            <div className='row g-0'>
                                <div className='col-md-6 pe-0 pe-md-4'>
                                    <Form.Label>Workspace Name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        value={workspaceName}
                                    />
                                </div>
                                <div className='col-md-6 ps-0 ps-md-4'>
                                    <Form.Label>Invite Code</Form.Label>
                                    <Form.Control
                                        type='text'
                                        value={inviteCode}
                                        readOnly
                                    />
                                    <Button variant='primary' className='mt-3'>Reset Code</Button>
                                </div>
                            </div>
                            <div className='text-end'>
                                <Button variant='primary' className='mt-3 mb-2'>Invite User <AiOutlinePlus size={18} /></Button>
                            </div>
                            <div className="table-responsive">
                                <table className='table table-borderless workspace-table align-middle text-center' style={{ minWidth: "620px" }}>
                                    <thead className='align-middle'>
                                        <tr>
                                            <th>Email</th>
                                            <th>Allowed LinkedIn Accounts</th>
                                            <th>Has Admin Rights</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            workspaceMembers.map((item) => {
                                                return <WorkspaceMemberItem key={item.id} data={item} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <Button variant='gray' className='mt-3 me-2'>Cancel</Button>
                            <Button variant='primary' className='mt-3'>Save</Button>
                        </div>
                    </div>
                    <div className='section input-style py-4'>
                        <h5 className='mb-3'>Security</h5>
                        <div className='row g-0'>
                            <div className='col-md-6 pe-0 pe-md-4'>
                                <Form.Label>Change Login Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='Enter Password'
                                />
                                <Button variant='primary' className='mt-3'>Set Password</Button>
                            </div>
                            <div className='col-md-6 mt-2 mt-md-0 ps-0 ps-md-4'>
                                <Form.Label>Change Security Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='Enter Password'
                                />
                                <Button variant='primary' className='mt-3'>Set Password</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Add Linkedin Account Popup */}
            <Modal show={show} onHide={handleShow} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add New LinkedIn Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Row style={{ rowGap: "12px" }}>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="linkedin_email"
                                            placeholder="Enter LinkedIn Email"
                                            value={formValues.linkedin_email}
                                            onChange={(e) => setFormValues({ ...formValues, 'linkedin_email': e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Enter LinkedIn Password"
                                            name="linkedin_password"
                                            value={formValues.linkedin_password}
                                            onChange={(e) => setFormValues({ ...formValues, 'linkedin_password': e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12}>
                                    <Form.Check
                                        type='checkbox'
                                        name='is_custom_proxy'
                                    >
                                        <Form.Check.Input
                                            type="checkbox"
                                            isValid
                                            onChange={(e) => setFormValues({ ...formValues, 'is_custom_proxy': e.target.checked })}
                                            checked={formValues.is_custom_proxy}
                                        />
                                        <Form.Check.Label className='text-dark ms-2'>
                                            Use my own proxy
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Col>

                                {
                                    formValues.is_custom_proxy && (
                                        <>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Proxy IP</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Proxy IP"
                                                        name="custom_proxy_ip"
                                                        value={formValues.custom_proxy_ip}
                                                        onChange={(e) => setFormValues({ ...formValues, 'custom_proxy_ip': e.target.value })}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Port</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Port"
                                                        name="custom_port"
                                                        value={formValues.custom_port}
                                                        onChange={(e) => setFormValues({ ...formValues, 'custom_port': e.target.value })}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Proxy Username</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Proxy Username"
                                                        name="custom_proxy_username"
                                                        value={formValues.custom_proxy_username}
                                                        onChange={(e) => setFormValues({ ...formValues, 'custom_proxy_username': e.target.value })}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Proxy Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Enter Proxy Password"
                                                        name="custom_proxy_password"
                                                        value={formValues.custom_proxy_password}
                                                        onChange={(e) => setFormValues({ ...formValues, 'custom_proxy_password': e.target.value })}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )
                                }

                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} md={3}>
                                    <Button variant="primary" type="submit" onClick={linkedInLogin}>
                                        Add Account
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}
