import React, { useState, useContext } from 'react';
import { BiUser } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { IoNotificationsOutline } from "react-icons/io5";
import { FiChevronDown } from "react-icons/fi";
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NotificationsBox from '../Notifications/NotificationsBox';
import { AuthContext } from '../../context/authContext.js';

export default function Topbar(props) {

    const { logout, user } = useContext(AuthContext);

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(!show);

    return (
        <section className='topbar py-3'>
            <div className='container px-0 px-sm-3'>
                <div className='row- g-0'>
                    <div className='col-md-12'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h2 className='mb-0'>{props.title !== null ? props.title : `Hey, ${user?.user?.first_name}`}</h2>
                            <div className="d-flex align-items-center gap-4">
                                <Dropdown className='d-flex d-sm-none'>
                                    <Dropdown.Toggle variant='white' className='notifications-toggle'>
                                        <div className='d-flex align-items-center justify-content-center position-relative' style={{ cursor: "pointer" }}>
                                            <IoNotificationsOutline size={22} />
                                            <p className='position-absolute px-1 bg-success text-white rounded mb-0' style={{ fontSize: "8px", right: "-4px", top: "-4px", fontWeight: "500" }}>4</p>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='shadow-sm'>
                                        <NotificationsBox />
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown align='end'>
                                    <Dropdown.Toggle variant='company-menu'>
                                        <div className='d-flex align-items-center'>
                                            <img src='/images/newson-logo.png' alt='' className='company-logo me-1 me-sm-3' />
                                            <span className='user-name'>Company</span><FiChevronDown size={20} className='ms-2' />
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='user-menu shadow-sm'>
                                        <Dropdown.Item>
                                            <p className='link mb-0'
                                            // onClick={handleShow}
                                            ><BiUser size={20} /> User Settings</p>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <Link to='#' className='link'><HiOutlineSwitchHorizontal size={20} /> Switch Workspace</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <Link to='#' className='link'><TbAdjustmentsHorizontal size={20} /> Workspace Preferences</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={logout}>
                                            <p className='link mb-0'><AiOutlinePoweroff size={20} /> Logout</p>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* User Setting Popup */}
            {/* <Modal show={show} onHide={handleShow} centered>
                <Modal.Header closeButton>
                    <Modal.Title>User Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter first name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter last name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter phone number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter company name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Company Address</Form.Label>
                            <Form.Control type="text" placeholder="Enter company address" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Company Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter company phone number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Company Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter company email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Company Website</Form.Label>
                            <Form.Control type="text" placeholder="Enter company website" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Company Logo</Form.Label>
                            <Form.Control type="file" placeholder="Enter company logo" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Company Description</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter company description" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Company Social Media</Form.Label>
                            <Form.Control type="text" placeholder="Enter company social media" />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal> */}



        </section>
    )
}
