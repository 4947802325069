import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt from 'jwt-decode'
import { toast } from 'react-hot-toast';
import axios from "axios";


const defaultProviderValue = {
    user: null,
    setUser: () => null,
    logout: () => Promise.resolve(),
}

const AuthContext = createContext(defaultProviderValue);

const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(defaultProviderValue.user);

    const naviagte = useNavigate();

    const initAuth = async () => {
        let storedToken = '';
        if (localStorage.getItem('accessToken') !== undefined) {
            storedToken = JSON.parse(localStorage.getItem('accessToken'))
        }

        if (storedToken) {

            let isExpired = false;
            let decodedToken = jwt(storedToken);
            let dateNow = new Date();

            if (decodedToken.exp * 1000 < dateNow.getTime()) {
                isExpired = true;
            }

            if (isExpired) {
                localStorage.removeItem('userData')
                localStorage.removeItem('accessToken')
                setUser(null)
                toast.error("Login Session Expired")
                naviagte('/login');
            } else {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/api/profiles/`, {
                    headers: {
                        'Authorization': `Bearer ${storedToken}`
                    }
                })

                setUser(response.data)
                // naviagte('/dashboard');
            }
        } else {
            setUser(null)
            naviagte('/login');
        }
    }

    useEffect(() => {
        initAuth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLogout = () => {
        setUser(null)
        toast.success("Logout Successful")
        window.localStorage.removeItem('userData')
        window.localStorage.removeItem('accessToken')
        naviagte('/login')
    }

    const values = {
        user,
        setUser,
        logout: handleLogout,
    }

    return (
        <AuthContext.Provider value={values}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }