// ** Styles Imports
import './App.scss';

// ** React-Router-Dom Imports
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// ** Pages Imports
import Dashboard from './Pages/Dashboard';
import Outreach from './Pages/Outreach';
import ViewOutreach from './Pages/ViewOutreach';
import Prospects from './Pages/Prospects';
import Inbox from './Pages/Inbox';
import Insights from './Pages/Insights';
import Settings from './Pages/Settings';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import CreateOutreach from './Pages/CreateOutreach';
import AdminPanel from "./Pages/AdminPanel";

// ** Toast Imports
import { Toaster } from 'react-hot-toast';

// ** Context Imports
import { AuthProvider } from './context/authContext';
import { UserProvider } from './context/userContext';

// ** Third Party Imports
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

function App() {
  return (
    <>
      <Toaster position='top-right' />

      <Router>
        <AuthProvider >
          <UserProvider>
            <Routes>
              <Route exact path={'/'} element={<Dashboard />} />
              <Route exact path={'/login'} element={<Login />} />
              <Route exact path={'/signup'} element={<Signup />} />
              <Route exact path={'/dashboard'} element={<Dashboard />} />
              <Route exact path={'/outreach'} element={<Outreach />} />
              <Route exact path={'/outreach/:id'} element={<ViewOutreach />} />
              <Route exact path={'/outreach/create'} element={<CreateOutreach />} />
              <Route exact path={'/outreach/edit'} element={<CreateOutreach />} />
              <Route exact path={'/prospects'} element={<Prospects />} />
              <Route exact path={'/inbox'} element={<Inbox />} />
              <Route exact path={'/insights'} element={<Insights />} />
              <Route exact path={'/settings'} element={<Settings />} />
              <Route exact path={"/admin-panel"} element={<AdminPanel />} />
              <Route exact path={'/linkedin'} element={<LinkedInCallback />} />
            </Routes>
          </UserProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
