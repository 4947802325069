// ** React Imports
import { useState, createContext } from "react";

// ** Third Party Imports
import axios from "axios";

// ** Default Context
const defaultProviderValue = {
    linkedinAccountList: [],
    setLinkedinAccountList: () => null,
    getLinkedinAccountList: () => null,
}

// ** Create Context
const UserContext = createContext(defaultProviderValue);

const UserProvider = ({ children }) => {

    // ** State
    const [linkedinAccountList, setLinkedinAccountList] = useState(defaultProviderValue.linkedinAccountList);

    // ** Get Linkedin Account List
    const handleGetLinkedinAccountList = async () => {
        const storedToken = JSON.parse(localStorage.getItem('accessToken'))
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/api/linkedinAccount/`, {
            headers: {
                'Authorization': `Bearer ${storedToken}`
            }
        })
        console.log(response);
        setLinkedinAccountList([response.data]);
    }

    // ** Values
    const values = {
        linkedinAccountList,
        setLinkedinAccountList,
        getLinkedinAccountList: handleGetLinkedinAccountList,
    }

    return (
        <UserContext.Provider value={values}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }