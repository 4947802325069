import React from 'react';
import { BsBriefcase, BsBuilding } from "react-icons/bs";

export default function ProspectItem({ data }) {
    return (
        <tr>
            <td>
                <img src={data.photo} alt='' />
            </td>
            <td>
                <p className='name' title={data.name}>{data.name}</p>
                <p className='status' title={data.status}>{data.status}</p>
            </td>
            <td>
                <p title={data.position}><BsBriefcase size={13} /> {data.position}</p>
                <p title={data.company}><BsBuilding size={13} /> {data.company}</p>
            </td>
            <td>
                <p>{data.email || "No Emails Found"}</p>
            </td>
            <td>
                <p>{data.phone || "No Phone Numbers Found"}</p>
            </td>
        </tr>
    )
}
