import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { countriesMap } from '../../constants/countriesMap.js'
import axios from 'axios';
import { toast } from 'react-hot-toast';

export default function SignupContent() {
    const [validated, setValidated] = useState(false);
    const [privacyModalShow, setPrivacyModalShow] = useState(false);
    const [termModalShow, setTermModalShow] = useState(false);

    const [currentFormPage, setCurrentFormPage] = useState(1);

    const [formValues, setFormValues] = useState({
        'first_name': '',
        'last_name': '',
        'company_name': '',
        'company_industry': '',
        'countries': '',
        'phone': '',
        'email': '',
        'password': '',
        'signup_agreement': false
    })

    const naviagte = useNavigate();

    const handleNext = (pageNo) => {
        const { isValid, errors } = validate(pageNo, formValues);
        if (!isValid) {
            toast.error(errors[0]);
        } else {
            setCurrentFormPage(pageNo);
        }
    }

    const handleBack = (pageNo) => {
        setCurrentFormPage(pageNo);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { isValid, errors } = validate(4, formValues);
        console.log("isValid: ", isValid);
        if (!isValid) {
            toast.error(errors[0]);
        } else {
            console.log("formValues: ", formValues);
            const id = toast.loading("Please wait...")
            try {
                const response = await axios.post(`${process.env.REACT_APP_LOCAL_BASE_URL}/api/signup/`, formValues);
                toast.success('Signup successful, Now Login', {
                    id: id,
                });
                console.log(response);
                naviagte('/login');
            } catch (error) {
                toast.error(error.response.data.error, {
                    id: id,
                });
                console.log(error);
            }
            setValidated(true);
        }
    };

    const validate = (page, values) => {
        const errors = [];
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        let isValid = true;

        if (page === 2) {
            if (!values.first_name) {
                errors.push("First Name is Required");
                isValid = false;
            }
            if (!values.last_name) {
                errors.push("Last Name is Required");
                isValid = false;
            }
        }
        if (page === 3) {
            if (!values.company_name) {
                errors.push("Company Name is Required");
                isValid = false;
            }
            if (!values.company_industry) {
                errors.push("Industry is Required");
                isValid = false;
            }
            if (!values.countries) {
                errors.push("Country is Required");
                isValid = false;
            }
        }
        if (page === 4) {
            if (!values.phone) {
                errors.push("Phone number is Required");
                isValid = false;
            }
            if (!values.email) {
                errors.push("Email is Required");
                isValid = false;
            } else if (!regex.test(values.email)) {
                errors.push("Please enter valid email");
                isValid = false;
            }
            if (!values.password) {
                errors.push("Password is Required");
                isValid = false;
            }
            if (!values.signup_agreement) {
                errors.push("Please accept privacy policy and terms of service");
                isValid = false;
            }
        }
        return { isValid, errors };
    };

    console.log(formValues);

    return (
        <div className='signup-content pt-5 pb-5'>
            <h2 className='text-center mb-4'>Create Account</h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit} >
                {
                    currentFormPage === 1 && (
                        <>
                            <div className='mb-3 mt-3'>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='first_name'
                                    placeholder='Enter First Name'
                                    required
                                    onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                    value={formValues.first_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Invalid First Name.
                                </Form.Control.Feedback>
                            </div>
                            <div className='mb-3 mt-3'>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='last_name'
                                    placeholder='Enter Last Name'
                                    required
                                    onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                    value={formValues.last_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Invalid Last Name.
                                </Form.Control.Feedback>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button type='button' onClick={() => handleNext(2)} variant='primary'>
                                    Next
                                </Button>
                            </div>
                            <p className='mt-3 text-center'>Already have an account?
                                <NavLink to={'/login'} className='colored-link'> Login</NavLink>
                            </p>
                        </>
                    )
                }
                {
                    currentFormPage === 2 && (
                        <>
                            <div className='mb-3 mt-3'>
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='company_name'
                                    placeholder='Enter Company Name'
                                    required
                                    onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                    value={formValues.company_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Invalid Company Name.
                                </Form.Control.Feedback>
                            </div>
                            <div className='mb-3 mt-3'>
                                <Form.Label>Company Industry</Form.Label>
                                <Form.Select
                                    name='company_industry'
                                    required
                                    onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                    value={formValues.company_industry}
                                >
                                    <option value=''>--Select--</option>
                                    <option value='Accounting'>Accounting</option>
                                    <option value='Accounting'>Accounting</option>
                                    <option value='Business'>Business</option>
                                    <option value='Technology'>Technology</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Invalid Company Industry.
                                </Form.Control.Feedback>
                            </div>
                            <div className='mb-3 mt-3'>
                                <Form.Label>Country</Form.Label>
                                <Form.Select
                                    name='countries'
                                    required
                                    onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                    value={formValues.countries}
                                >
                                    <option value=''>--Select--</option>
                                    {
                                        countriesMap.map((country) => {
                                            return <option key={country.name} value={country.name}>{country.name}</option>
                                        })
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Invalid Country.
                                </Form.Control.Feedback>
                            </div>
                            <div className="d-flex justify-content-between">
                                <Button type='button' onClick={() => handleBack(1)} variant='outline-secondary'>
                                    Back
                                </Button>
                                <Button type='button' onClick={() => handleNext(3)} variant='primary'>
                                    Next
                                </Button>
                            </div>
                            <p className='mt-3 text-center'>Already have an account?
                                <NavLink to={'/login'} className='colored-link'> Login</NavLink>
                            </p>
                        </>
                    )
                }
                {
                    currentFormPage === 3 && (
                        <>
                            <div className='mb-3 mt-3'>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type='tel'
                                    name='phone'
                                    placeholder='Enter Phone'
                                    required
                                    onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                    value={formValues.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Invalid Phone.
                                </Form.Control.Feedback>
                            </div>
                            <div className='mb-3 mt-3'>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type='email'
                                    name='email'
                                    placeholder='Enter Email Address'
                                    required
                                    onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                    value={formValues.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Invalid Email.
                                </Form.Control.Feedback>
                            </div>
                            <div className='mb-3'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    name='password'
                                    placeholder='Enter Password'
                                    required
                                    onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                    value={formValues.password}
                                    autoComplete='false'
                                />
                                <Form.Control.Feedback type="invalid">
                                    Invalid password.
                                </Form.Control.Feedback>
                            </div>
                            <div className='mb-3'>
                                <Form.Check
                                    type='checkbox'
                                    name='signup_agreement'
                                >
                                    <Form.Check.Input
                                        type="checkbox"
                                        isValid
                                        onChange={(e) => setFormValues({ ...formValues, 'signup_agreement': e.target.checked })}
                                        checked={formValues.signup_agreement}
                                    />
                                    <Form.Check.Label className='text-dark ms-2'>
                                        I have read and accept the&nbsp;
                                        <span className='text-primary' role='button' onClick={() => setPrivacyModalShow(true)}>Privacy Policy</span>
                                        &nbsp;and&nbsp;
                                        <span className='text-primary' role='button' onClick={() => setTermModalShow(true)}>Terms of Service</span>
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Control.Feedback type="invalid">
                                    Not Checked.
                                </Form.Control.Feedback>
                            </div>
                            <div className="d-flex justify-content-between">
                                <Button type='button' onClick={() => handleBack(2)} variant='outline-secondary'>
                                    Back
                                </Button>
                                <Button type='submit' variant='primary'>
                                    Signup
                                </Button>
                            </div>
                            <p className='mt-3 text-center'>Already have an account?
                                <NavLink to={'/login'} className='colored-link'> Login</NavLink>
                            </p>
                        </>
                    )
                }
            </Form>

            <PrivacyPolicy
                show={privacyModalShow}
                onHide={() => setPrivacyModalShow(false)}
            />
            <TermService
                show={termModalShow}
                onHide={() => setTermModalShow(false)}
            />
        </div>
    )
}


export const PrivacyPolicy = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ paddingLeft: 0 }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Privacy Policy
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>What Kinds of Information Do We Collect?</h6>
                <p>
                    Buzz Outreach Limited, (“Buzz”) understands that your privacy is important to you and that you care about how your personal data is used and shared online. We respect and value the privacy of everyone who visits this website, https://www.buzz.ai or https://dash.buzz.ai (“Our Site”) and will only collect and use personal data in ways that are described here, and in a manner that is consistent with Our obligations and your rights under the law.
                </p>

                <h6>What Data Do We Collect?</h6>
                <p>
                    <ul>
                        <li>demographic information such as post code, preferences, and interests.</li>
                        <li>financial information such as credit/debit card numbers.</li>
                        <li>IP address</li>
                        <li>web browser type and version</li>
                        <li>operating system</li>
                        <li>a list of URLs starting with a referring site, your activity on Our Site, and the site you exit to.</li>
                    </ul>
                </p>

                <p>
                    Our use of your personal data will always have a lawful basis, either because it is necessary for Our performance of a contract with you, because you have consented to Our use of your personal data (e.g. by subscribing to emails), or because it is in Our legitimate interests. Specifically, We may use your data for the following purposes:
                </p>
                <p>
                    <ul>
                        <li>Providing and managing your access to Our Site.</li>
                        <li>Personalizing and tailoring your experience on Our Site.</li>
                        <li>Supplying Our products and services to you (please note that We require your personal data in order to enter into a contract with you).</li>
                        <li>Personalizing and tailoring Our products and services for you.</li>
                        <li>Replying to emails from you</li>
                        <li>Supplying you with emails that you have opted into (you may unsubscribe or opt-out at any time.</li>
                        <li>Market research.</li>
                        <li>Analyzing your use of Our Site and gathering feedback to enable Us to continually improve Our Site and your user experience.</li>
                    </ul>
                </p>
                <p>
                    With your permission and/or where permitted by law, We may also use your data for marketing purposes which may include contacting you by email, telephone, text message and post with information, news, and offers on Our products and services. We will not, however, send you any unsolicited marketing or spam and will take all reasonable steps to ensure that We fully protect your rights and comply with Our obligations under the GDPR and the Privacy and Electronic Communications (EC Directive) Regulations 2003.
                </p>

                <h6>GOOGLE API SERVICES USER DATA POLICY</h6>
                <p>The Buzz Dashboard uses and transfers to any other app of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.</p>
                <p>We do not keep your personal data for any longer than is necessary for the reason(s) of which it was first collected.</p>
                <p>In certain circumstances, We may be legally required to share certain data held by us, which may include your personal data, for example, where We are involved in legal proceedings, where We are complying with legal obligations, a court order, or a governmental authority.</p>
                <p>
                    We may sometimes contract with third parties to supply products and services to you on Our behalf. These may include payment processing, delivery of goods, search engine facilities, advertising, and marketing. In some cases, the third parties may require access to some or all of your data. Where any of your data is required for such a purpose, We will take all reasonable steps to ensure that your data will be handled safely, securely, and in accordance with your rights, Our obligations, and the obligations of the third party under the law.
                </p>
                <p>We may compile statistics about the use of Our Site including data on traffic, usage patterns, user numbers, sales, and other information. All such data will be anonymized and will not include any personally identifying data, or any anonymized data that can be combined with other data and used to identify you. We may from time to time share such data with third parties such as prospective investors, affiliates, partners, and advertisers. Data will only be shared and used within the bounds of the law.</p>
                <h6>
                    CONTACTING US
                </h6>
                <p>
                    If you have any questions about Our Site, Service or this Privacy Policy, please contact Us by email at success@buzz.ai. Please ensure that your query is clear, particularly if it is a request for information.
                </p>
                <h6>
                    ENTIRE AGREEMENT
                </h6>
                <p>
                    Except as otherwise set forth or referred to in this Agreement, this Agreement constitutes the sole and entire Agreement and understanding between the parties hereto as to the subject matter hereof and supersedes all prior discussions, agreements, and understandings of every kind and nature between them as to such subject matter.
                </p>
                <h6>
                    SEVERABILITY
                </h6>
                <p>
                    If any provision of this Agreement is held to be illegal, invalid, or unenforceable under any present or future law, then that provision will be fully severable. In such instance, this Agreement will be construed and enforced as if the illegal, invalid, or unenforceable provision had never comprised a part of this Agreement, and the remaining provisions of this Agreement will remain in full force and effect.
                </p>
            </Modal.Body>
        </Modal>
    )
}

export const TermService = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ paddingLeft: 0 }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Terms of Service
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    By purchasing our services, you agree to all terms and conditions highlighted in this User Agreement and Terms and Conditions below. This constitutes a legally binding agreement between the Newson SaaS App Application, it’s Website and  its users (the client, “You”).
                </p>
                <p>
                    During the term of this User Agreement, Newson will provide a license to use their SaaS  app and digital product to the client as described on the Newson website hereto (the “Services”).  It’s important that the client understands the expectations of the service plan they have purchased so they know what to expect by reading the entirety of the Terms of Service and User Service Agreement. It is the responsibility of the customer to fully read and acknowledge all aspects of the Newson User Agreement  before purchasing any of Newson’s products and services, as they declare their legally binded approval to this agreement by purchasing, using and downloading any of Newson’s services and SaaS application usage.
                </p>
                <p>
                    The Client shall be required to pay for the license use of the SaaS app and digital product services that Newson provides to the client. The Client shall pay to Newson the amounts and payment schedule specified on the plan purchased on the Newson website.
                </p>
                <p>
                    THE USER AGREEMENT: The use of this website and services on this website provided by Newson (hereinafter referred to as “Company”) are subject to the following Terms of Service and Service Agreement (hereinafter the “Agreement”), all parts and sub-parts of which are specifically incorporated by reference here. This Agreement shall govern the use of all pages on this website (hereinafter collectively referred to as “Website”) and any services provided by or on this Website (“Services”), aswell as within the SaaS App Newson Application (Hosted at V2.Newson.io). Newson is not a LinkedIn or Email product. You understand that like any third-party software or tools, LinkedIn Corporation does not endorse the use of Newson nor does LinkedIn Corporation have any association with Newson.  You accept this at your full liability and responsibility when purchasing or using any of Newson’ services. Newson does not hold or accept any liability for any of your use of Newson’s services.
                </p>
                <h6>1) DEFINITIONS</h6>
                <p>
                    The parties referred to in this User Agreement shall be defined as follows:
                </p>
                <p>
                    Company, Us, We: The Company, as the creator, operator, and publisher of the Website, makes the Website, and certain Services on it, including the SaaS app, available to users. Newson, Company, Us, We, Our, Ours and other first-person pronouns will refer to the Company, as well as all employees and affiliates of the Company.
                </p>
                <p>
                    You, the User, the Customer, the Client: You, as the user of the Website, the Newson SaaS App will be referred to throughout this Agreement with second-person pronouns such as You, Your, Yours, or as User or Client.
                </p>
                <p>
                    Parties: Collectively, the parties to this Agreement (the Company and You) will be referred to as Parties.
                </p>

                <h6>2) ASSENT & ACCEPTANCE</h6>
                <p>
                    By using the Website and the Newson SaaS app, You warrant that you have read and reviewed this User Agreement and that You agree to be bound by it. If You do not agree to be bound by this Agreement, please leave the Website and Saas App immediately and do not purchase any of Newson’s services. The Company only agrees to provide use of this Website and Saas App and Digital Product Services to you if you agree to this  User Agreement.
                </p>

                <h6>3) AGE RESTRICTION</h6>
                <p>
                    You must be at least 18 (eighteen) years of age to use this Website or any Services contained herein. By using this Website, SaaS app, You represent and warrant that You are at least 18 years of age and may legally agree to this Agreement. The Company assumes no responsibility or liability for any misrepresentation of Your age.
                </p>

                <h6>4) LICENCE TO USE WEBSITE AND SAAS APP</h6>
                <p>
                    The Company may provide You with certain information as a result of Your use of the Website or SaaS App Services. Such information may include, but is not limited to, documentation, data, or information developed by the Company, and other materials which may assist in Your use of the Website or Services (“Company Materials”). Subject to this Agreement, the Company grants You a non-exclusive, limited, non-transferable and revocable licence to use the Company Materials solely in connection with Your use of the Website and Services. Newson’s paid licence is non refundable. The Company Materials may not be used for any other purpose and this licence terminates upon Your cessation of use of the Website or Services or at the termination of this Agreement.
                </p>

                <h6>5) INTELLECTUAL PROPERTY</h6>
                <p>
                    You agree that the Website and all Saas App, and Digital Assets and Services provided by the Company are the property of the Company, including all copyrights, trademarks, trade secrets, patents and other intellectual property (“Company IP”). You agree that the Company owns all right, title and interest in and to the Company IP and that You will not use the Company IP for any unlawful or infringing purpose. You agree not to reproduce or distribute the Company IP in any way, including electronically or via registration of any new trademarks, trade names, service marks or Uniform Resource Locators (URLs), without express written permission from the Company.
                </p>

                <h6>6) USER OBLIGATIONS</h6>
                <p>
                    As a user of the Website or Services, You may be asked to register with Us. When You do so, You will choose a user identifier, which may be Your email address or another term, as well as a password. You may also provide personal information, including, but not limited to, Your name. You are responsible for ensuring the accuracy of this information. This identifying information will enable You to use the Website and Services. You must not share such identifying information with any third party and if You discover that Your identifying information has been compromised, You agree to notify Us immediately in writing. Email notification will suffice. You are responsible for maintaining the safety and security of Your identifying information as well as keeping Us apprised of any changes to Your identifying information. Providing false or inaccurate information, or using the Website or Services to further fraud or unlawful activity is grounds for immediate termination of this Agreement.</p>

                <h6>7) ACCEPTABLE USE</h6>
                <p>
                    You agree not to use the Website or Services for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website or Services in any way that could damage the Website, Services or general business of the Company.
                </p>
                <p>
                    You further agree not to use the Website or Services:

                    To harass, abuse, or threaten others or otherwise violate any person’s legal rights;

                    To violate any intellectual property rights of the Company or any third party;

                    To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;

                    To perpetrate any fraud;

                    To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;

                    To publish or distribute any obscene or defamatory material;

                    To publish or distribute any material that incites violence, hate or discrimination towards any group;

                    To unlawfully gather information about others.
                </p>

                <h6>8)  Privacy Policy</h6>
                <p>
                    Through Your Use of the Website and Services, You may provide Us with certain information. By using the Website or the Services, You authorize the Company to use Your information in the United Kingdom and any other country where We may operate.
                </p>
                <p>
                    Information We May Collect or Receive: When You register for an account, You provide Us with a valid email address and may provide Us with additional information, such as Your name or billing information. Depending on how You use Our Website or Services, We may also receive information from external applications You use to access Our Website, or We may receive information through various web technologies, such as cookies, log files, clear gifs, web beacons or others.
                </p>

                <p>
                    How We Use Information: We use the information gathered from You to ensure Your continued good experience on Our website, including through email communication. We may also track certain of the passive information received to improve Our marketing and analytics, and for this, We may work with third-party providers.
                </p>

                <p>
                    How You Can Protect Your Information: If You would like to disable Our access to any passive information We receive from the use of various technologies, You may choose to disable cookies in Your web browser. Please be aware that the Company will still receive information about You that You have provided, such as Your email address. If You choose to
                </p>
                <p>
                    terminate Your account, the Company will store information about You for the following number of days: 30. After that time, it will be deleted
                </p>
                <p>
                    We use SSL (Secure Sockets Layer) for connections between client side (website & app) and our servers to protect your data
                </p>

                <p>
                    We do not collect any payment information of any kind.
                </p>

                <p>
                    We store only your Newson account information (email, licenses, team members) on our servers
                </p>

                <h6>
                    9) REVERSE ENGINEERING & SECURITY
                </h6>

                <p>You agree not to undertake any of the following actions:
                </p>
                <p>
                    Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website or Services;
                </p>
                <p>
                    Violate the security of the Website or Services through any unauthorized access, circumvention of encryption or other security tools, data mining or interference to any host, user or network.
                </p>

                <h6>
                    10) DATA LOSS
                </h6>
                <p>
                    The Company does not accept responsibility for the security of Your account or content. You agree that Your use of the Website or Services is at Your own risk.
                </p>

                <h6>
                    11) INDEMNIFICATION
                </h6>
                <p>
                    You agree to defend and indemnify the Company and any of its affiliates (if applicable) and hold Us harmless against any and all legal claims and demands, including reasonable attorney’s fees, which may arise from or relate to Your use or misuse of the Website or Services, Your breach of this Agreement, or Your conduct or actions. You agree that the Company shall be able to select its own legal counsel and may participate in its own defence, if the Company wishes.
                </p>

                <h6>
                    12) SPAM POLICY
                </h6>
                <p>
                    You are strictly prohibited from using the Website or any of the Company’s Services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.
                </p>

                <h6>
                    13) THIRD-PARTY LINKS & CONTENT
                </h6>
                <p>
                    The Company may occasionally post links to third party websites or other services. You agree that the Company is not responsible or liable for any loss or damage caused as a result of Your use of any third party services linked to from Our Website.
                </p>

                <h6>
                    14) MODIFICATION & VARIATION
                </h6>

                <p>
                    The Company may, from time to time and at any time without notice to You, modify this Agreement. You agree that the Company has the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this Agreement are in full force and effect immediately upon posting on the Website and that modifications or variations will replace any prior version of this Agreement, unless prior versions are specifically referred to or incorporated into the latest modification or variation of this Agreement.
                </p>

                <p>
                    To the extent any part or sub-part of this Agreement is held ineffective or invalid by any court of law, You agree that the prior, effective version of this Agreement shall be considered enforceable and valid to the fullest extent.
                </p>

                <p>
                    You agree to routinely monitor this Agreement and refer to the Effective Date posted at the top of this Agreement to note modifications or variations. You further agree to clear Your cache when doing so to avoid accessing a prior version of this Agreement. You agree that Your continued use of the Website after any modifications to this Agreement is a manifestation of Your continued assent to this Agreement.
                </p>

                <p>
                    In the event that You fail to monitor any modifications to or variations of this Agreement, You agree that such failure shall be considered an affirmative waiver of Your right to review the modified Agreement.
                </p>

                <h6>
                    15) ENTIRE AGREEMENT
                </h6>
                <p>
                    This Agreement constitutes the entire understanding between the Parties with respect to any and all use of this Website. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of this Website.
                </p>
                <h6>
                    16) SERVICE INTERRUPTIONS , SERVICE IS DELIVERED ON AN “AS IS BASIS”
                </h6>
                <p>
                    The Company may need to interrupt Your access to the Website or SaaS App  or Ai Sales Reps (supplied Linkedin profiles) to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that Your access to the Website or Saas App or SaaS App may be affected by unanticipated or unscheduled downtime, for any reason, but that Newson (the Company), shall have no liability for any damage, loss, or time usage caused as a result of such downtime. You accept this clause by purchasing any of Newson’s services. Maintenance and Tech/ Linkedin Profile Issues are a frequent and regular occurrence due to the nature of service and you accept that by using these services. Your (Real) or Our (Supplied) Linkedin Profiles can block or be restricted at any time, without notice, by Linkedin, and our Supplied Linkedin Profiles will be replaced by Newson. Linkedin and email automation can have issues and not work or have bugs at anytime, without notice, and you accept that these risks by going ahead. We do not promise or advertise an issue free, perfect service, due to the nature of our SaaS app and digital product service, and we deliver our service only on an AS IS Basis. The AS IS basis, means it is delivered on how Newson deliver it, and cannot be compared to other opinions, industries or companies to base your judgement or expectation on the level and quality of service that should be delivered.
                </p>

                <h6>
                    17) TERM, TERMINATION & SUSPENSION
                </h6>
                <p>
                    The Company may terminate this Agreement with You at any time for any reason, with or without cause. The Company specifically reserves the right to terminate this Agreement if You violate any of the terms outlined herein, including, but not limited to, violating the intellectual property rights of the Company or a third party, failing to comply with applicable laws or other legal obligations, and/or publishing or distributing illegal material. If You have registered for an account with Us, You may also terminate this Agreement at any time by contacting Us and requesting termination. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall remain in full force and effect.
                </p>
                <p>
                    The Client agrees and acknowledges that once they purchase one of Newson’s services they must follow the correct Termination and Cancellation Policy set by Newson in order to cancel their service plan correctly. The following termination and cancellation rules apply.
                </p>
                <p>
                    In order to cancel a service plan with Newson, the Client must send a cancellation notice in writing by email to the Newson Billing Team at Billing@Newson.io. This must be sent to the Newson billing team at least 48 hours (2 days) before the Client’s renewal date to be accepted. Newson does not allow same day or 24 hour cancellation notices. This is due to the reason it takes 48 hours for our systems to cancel and delete the Client’s account and database and will incur further costs to Newson if not deleted within this window.

                    The Client agrees and acknowledges following their termination, they will not be paid or compensated for any portion of the Services Subscription that will not be delivered.
                </p>
                <p>
                    Should the Client default in a payment, such as not paying their owed payment to Newson, Newson may terminate the Agreement and service plan immediately without Notice and Newson will not be liable for any compensation, refunds or any fees to the Client.
                </p>

                <h6>
                    18) NO WARRANTIES
                </h6>
                <p>
                    You agree that Your use of the Website and Saas App Services is at Your sole and exclusive risk and that any Services provided by Us are on an “As Is” basis.
                </p>
                <p>
                    The Company hereby expressly disclaims any and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. The Company makes no warranties that the Website or Services will meet Your needs or that the Website or Services will be uninterrupted, error-free, or secure. The Company also makes no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. You agree that any damage that may occur to You, through Your computer system, or as a result of loss of Your data from Your use of the Website or Services is Your sole responsibility and that the Company is not liable for any such damage or loss.
                </p>

                <h6>
                    19) STRICT NO REFUND POLICY
                </h6>
                <p>
                    You agree and acknowledge that by receiving a license to use the Newson SaaS app and Digital products that you are not entitled to a refund once you sign up or purchase any of Newson’s products and service plans. This is due to the difference in nature of a licensed SaaS digital product versus a non-digital product. The company is not liable for any refunds that you request once you have purchased one of our services. Newson upholds a strict “No Refund Policy”.

                    This applies to all Software Plans, Lead Plans, Appointment Plans and Close Plans on the Newson website featured on this link here – <a href="https://www.newson.io/#pricing">https://www.newson.io/#pricing.</a>

                    This also applies to if you are a White Label customer and have purchased any of the White Label SaaS and Service plans on <a href="https://www.newson.io/whitelabel/">https://www.newson.io/whitelabel/.</a>
                </p>

                <p>
                    Newson does not provide refunds since its product and services incur costs when the customer downloads a license to use the SaaS app when providing its services to its customers and therefore cannot supply a refund once purchased. Newson supplies its service on a “As Is” basis  with a “No Liability” Clause in the Service Agreement and expressly claims that the service can have disruptions and issues, due to the nature of its SaaS app service and its customers must be aware and accepting of that.  So the customer cannot request a refund after receiving a license to use the app.
                </p>

                <p>
                    Newson reserves the right not to provide a refund based on the fact that their offering is very clearly advertised on the website and the Client cannot expect a refund based on a “Change of Heart” once purchased from Newson. This is to prevent a waste of resources and inconvenience to Newson which is involved in handling the Client prior, during and after purchasing a service from Newson. If the Client is unsatisfied based on the Performance or Service expectation, the Client should be understanding that Newson does not promise any service guarantees on any of its plan apart from the 2023 Guaranteed Bundle plans and therefore should be aware of the service expectations is delivered on an AS IS Basis.
                </p>

                <h6>
                    20) STRICT NO SERVICE GUARANTEE POLICY
                </h6>
                <p>
                    The Company is not liable for any service guarantee or guarantees of service. Newson offers a “Business Development Service” and enables its customers a means and way of generating Business to Business Leads, Appointments and Sales.
                </p>
                <p>
                    During the term of this Agreement, unless the Client has purchased and signed the “2023 Guaranteed Bundle Plan” the Client agrees and acknowledges that Newson does not promise any service guarantees for the Subscriptions and Pay Per Appointment plans. Newson sets expected rates of performances, based on averages of other similar client campaigns; however Newson cannot assure it will be guaranteed for the Clients particular use case. This relates to the amount of prospects contacted, amount of leads, appointments and close deals generated per month.
                </p>
                <p>
                    Newson does uphold the promise that they will try their best to achieve the best results possible for the Client’s business.
                </p>

                <h6>
                    21) LEAD TIMES
                </h6>
                <p>
                    The Company does not uphold any guarantees or specific lead times when it comes to customer service, campaign creation, and White Label set up. Typically we will try to get back to you within 24-48 hours if you are reaching out for customer support, campaign creation building campaigns can take up to 5-14 days, depending on the complexity and amount of campaigns are campaign team have to create, we will of course not start your subscription billing if it takes more than a reasonable amount of time, such as over 7 days to build. White Label Software setup typically takes 48 hours but can take up to 7-10 working days.
                </p>

                <h6>
                    22) STRICT PAYMENT POLICY
                </h6>
                <p>
                    All Payments and Subscription Billing is taken on an Advanced Monthly Recurring Basis to allow for Customer 30 day use of Newson’s Product and Services. If Payment is not provided on the Exact Billing Date, and Renewed Billing Date, at the date and time Newson require, Newson has every right to terminate all agreements,  use, pause access from our services with immediate effect and still request the payment in full, and subsequent Months in full, until it is paid and/or the Subscriptions are Cancelled by the Customer. All Customers agree and understand that by signing up to a Newson Monthly Recurring Subscription payment plan you verbally agree to our Strict Payment Policy to pay for our services at the agreed rate, on a monthly recurring basis, unless you request cancellation by giving in writing to our Cancellations and Billing team at Billing@Newson.io your declaration to cancel your subscription, or are terminated by Newson for infringing our strict payment policy.
                </p>
                <p>
                    Newson declares that all customers are still required to pay for the Month of service due even if the full 30 days is not provided. Newson has a strict payment policy that customers acknowledge and agree by signing up and using our services, as the product and services require immediate software costs to provide the service to the customer(s). Newson therefore requests and is owed the payment in full, even if the full 30 days are not supplied to the customer, to protect its financial and business interests and the service and product continued opportunity and benefit to its
                </p>

                <p>
                    For the Subscription Plans, the Client agrees and acknowledges that they will be charged the amount on the subscription renewal date of every month following signing up, regardless of the results or return they have received unless they request to cancel with at least 48 hours notice to their renewal date. This is because the product is a rolling monthly subscription.
                </p>

                <p>
                    For all Pay Per Appointment plans, the client is required to pay for all appointments booked immediately when the appointment is booked. They understand and acknowledge that their card will be charged the appointment amount at any time that an appointment is booked.
                </p>

                <p>
                    The Client agrees that they will be bound and liable to pay these charges to Newson by the jurisdiction and laws of England Wales. And if not paid they could be summoned to the UK courts by Newson for a claim of these fees plus further compensation of refusal and delay of payment if not paid at the time we require.customers.
                </p>
                <p>
                    If the Client does not pay the required owed invoices and subscriptions to Newson, at the time specified, the client could
                </p>

                <p>
                    risk their bills and overdue invoices, being sent to Newson’s Collections Agency, which will negatively impact their credit score.
                </p>

                <h6>
                    23) STRICT CANCELLATION POLICY
                </h6>
                <p>
                    The Client agrees and acknowledges that once they purchase one of Newson’s services they must follow the correct Termination and Cancellation Policy set by Newson in order to cancel their service plan correctly. The following termination and cancellation rules apply.
                </p>
                <p>
                    If a Customer wishes to pause, or cancel their subscription and services with Newson, they must do so by giving in writing to our Cancellations and Billing team at Billing@Newson.io to provide their declaration to cancel their Newson service and subscription, in ADVANCE of their renewal date. This must be a minimum of 48 hours Cancellation Notice. We do not accept Same Day Cancellation Notices. Due to prevention of Newson incurring any financial losses due to your cancellation. Only then, will billing be stopped and you will not be due and owing payments to Newson. Once you request cancellation, you will then be allowed access and continued service until the renewal period when the service and access will end and be terminated.
                </p>
                <p>
                    This must be sent to the Newson billing team at least 48 hours (2 days) before the Client’s renewal date to be accepted. Newson does not allow same day or 24 hour cancellation notices. This is due to the reason it takes 48 hours for our systems to cancel and delete the Client’s account and database and will incur further costs to Newson if not deleted within this window.
                </p>
                <p>
                    The Client agrees and acknowledges following their termination, they will not be paid or compensated for any portion of the Services Subscription that will not be delivered.
                </p>
                <p>
                    Should the Client default in a payment, such as not paying their owed payment to Newson, Newson may terminate the Agreement and service plan immediately without Notice and Newson will not be liable for any compensation, refunds or any fees to the Client.
                </p>
                <p>
                    If the Client does not pay the required owed invoices and subscriptions to Newson, at the time specified, the client could risk their bills and overdue invoices, being sent to Newson’s Collections Agency, which will negatively impact their credit score.
                </p>

                <h6>
                    24) NO LIABILITIES
                </h6>
                <p>
                    The Company is not liable for any damages that may occur to You as a result of Your use of the Website or SaaS App Services, to the fullest extent permitted by law. The maximum liability of the Company arising from or relating to this Agreement is limited to the greater of one hundred ($100) US Dollars or the amount You paid to the Company in the last six (6) months. This section applies to any and all claims by You, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.
                </p>
                <p>
                    Newson is not a LinkedIn or Email product. You understand that like any third-party software or tools, LinkedIn Corporation does not endorse the use of Newson nor does LinkedIn Corporation have any association with Newson.  You accept this at your full liability and responsibility when purchasing or using any of Newson’ services. Newson does not hold or accept any liability for any of your use of Newson’s services.
                </p>
                <p>
                    This includes but is not limited to, any liabilities or damage that may occur as a result of using Newson’s services. You accept that by using Newson’s services you do this at your own liability and risk.
                </p>

                <h6>
                    25) GENERAL PROVISIONS
                </h6>
                <p>
                    LANGUAGE: All communications made or notices given pursuant to this Agreement shall be in the English language.
                </p>
                <p>
                    JURISDICTION, VENUE & CHOICE OF LAW: Through Your use of the Website or Services, You agree that the laws of England and Wales shall govern any matter or dispute relating to or arising out of this Agreement, as well as any dispute of any kind that may arise between You and the Company, with the exception of its conflict of law provisions. In case any litigation specifically permitted under this Agreement is initiated, the Parties agree to submit to the personal jurisdiction of England and Wales. The Parties agree that this choice of law, venue, and jurisdiction provision is not permissive, but rather mandatory in nature. You hereby waive the right to any objection of venue, including assertion of the doctrine of forum non conveniens or similar doctrine.
                </p>
                <p>
                    ARBITRATION: In case of a dispute between the Parties relating to or arising out of this Agreement, the Parties shall first attempt to resolve the dispute personally and in good faith. If these personal resolution attempts fail, the Parties shall then submit the dispute to binding arbitration. The arbitration shall be conducted in the United Kingdom. The arbitration shall be conducted by a single arbitrator and such arbitrator shall have no authority to add Parties, vary the provisions of this Agreement, award punitive damages, or certify a class. The arbitrator shall be bound by applicable and governing law of the United Kingdom. Each Party shall pay their own costs and fees. Claims necessitating arbitration under this section include, but are not limited to: contract claims, tort claims based on local laws, ordinances, statutes or regulations. Intellectual property claims by the Company will not be subject to arbitration and may, as an exception to this sub-part, be litigated. The Parties, in agreement with this sub-part of this Agreement, waive any rights they may have to a jury trial in regard to arbitral claims.
                </p>
                <p>
                    ASSIGNMENT: This Agreement, or the rights granted hereunder, may not be assigned, sold, leased or otherwise transferred in whole or part by You. Should this Agreement, or the rights granted hereunder, by assigned, sold, leased or otherwise transferred by the Company, the rights and liabilities of the Company will bind and inure to any assignees, administrators, successors and executors.
                </p>
                <p>
                    SEVERABILITY: If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such condition, the remainder of this Agreement shall continue in full force. NO WAIVER: In the event that We fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or of any other provision. Waiver of any part or sub-part of this Agreement will not constitute a waiver of any other part or sub-part. HEADINGS FOR CONVENIENCE ONLY: Headings of parts and sub-parts under this Agreement are for convenience and organization, only. Headings shall not affect the meaning of any provisions of this Agreement.
                </p>
                <p>
                    NO AGENCY, PARTNERSHIP OR JOINT VENTURE: No agency, partnership, or joint venture has been created between the Parties as a result of this Agreement. No Party has any authority to bind the other to third parties.
                </p>
                <p>
                    FORCE MAJEURE: The Company is not liable for any failure to perform due to causes beyond its reasonable control including, but not limited to, acts of God, acts of civil authorities, acts of military authorities, riots, embargoes, acts of nature and natural disasters, and other acts which may be due to unforeseen circumstances.
                </p>
                <p>
                    ELECTRONIC COMMUNICATIONS PERMITTED: Electronic communications are permitted to both Parties under this Agreement, including e-mail or fax. For any questions or concerns, please email Us at the following address: contact@newson.io
                </p>

            </Modal.Body>
        </Modal>
    )
}
