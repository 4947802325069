import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Form, Dropdown } from 'react-bootstrap';
import { FiEye, FiUsers, FiDownload } from "react-icons/fi";
import { MdOutlineReplay, MdOutlineChat } from "react-icons/md";
import { HiOutlineDuplicate } from "react-icons/hi";
import { AiOutlineDelete } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";

export default function OutreachBox({
    data,
    listView,
    selectedForDelete,
  }) {
    return (
        <div className={listView ? 'outreach-box box-card p-3 mb-3' : 'outreach-box tile-view box-card p-3 mb-3'}>
            <div className='outreach-head d-flex justify-content-between'>
                <div className='outreach-head-start gap-3 gap-md-0 d-flex flex-wrap'>
                    <div className='d-flex'>
                        {data.status ?
                            <Form.Check
                                type='checkbox'
                                name='outreach-status-input'
                                defaultChecked
                            /> :
                            <Form.Check
                                type='checkbox'
                                name='outreach-status-input'
                            />}
                        <div className='ms-2 me-4'>
                            <p className='fw-600 mb-1'>{data.name}</p>
                            <div className='d-flex outreach-info flex-column flex-sm-row'>
                                <p>{data.progress}% complete</p>
                                <p className='px-2 d-none d-sm-inline'>-</p>
                                <p>{data.createdAt}</p>
                                <p className='px-2 d-none d-sm-inline'>-</p>
                                <p>{data.steps} steps</p>
                            </div>
                        </div>
                    </div>
                    {<div className='members d-flex align-items-center'>
                        {
                            data.members.slice(0, 2).map((item) => {
                                return <img key={item.id} src={item.image} alt='' />
                            })
                        }
                        {
                            data.members.length > 2 ?
                                <div className='more d-flex align-items-center justify-content-center'>
                                    +{data.members.slice(2).length}
                                </div> :
                                ''
                        }
                        <p className='members-count mb-0 ms-3'>{data.members.length} Members</p>
                    </div>}
                </div>
                <div className='outreach-head-end'>
                    <div className='outreach-actions d-flex'>
                        <NavLink to={'/outreach/' + data.encrypted_id} style={{ border: 'none', color: 'black' }}>
                            <Button variant='action' className='me-2'>
                                <FiEye size={18} />
                            </Button>
                        </NavLink>
                        {/* <Button variant='action'>
                            <BiDotsVerticalRounded size={18} />
                        </Button> */}
                        <div className="topbar">
                            <Dropdown>
                                <Dropdown.Toggle variant='action'>
                                    <BiDotsVerticalRounded size={18} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='user-menu shadow-sm'>
                                    <Dropdown.Item className="link d-flex mb-0">
                                        <FiUsers size={18} />
                                        <p>Check Prospects</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="link d-flex">
                                        <HiOutlineDuplicate size={18} />
                                        <p>Dulicate Campaign</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="link d-flex">
                                        <MdOutlineChat size={18} />
                                        <p>Open Chat</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="link d-flex">
                                        <FiDownload size={18} />
                                        <p>Export Data</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="link d-flex">
                                        <MdOutlineReplay size={18} />
                                        <p>Rerun Searches</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="link d-flex mb-0"
                                        onClick={() => selectedForDelete(data)}
                                    >
                                        <AiOutlineDelete size={18} />
                                        <p>Delete Campaign</p>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className='outreach-body gap-3 gap-md-0 mt-3 pt-3 d-flex flex-wrap justify-content-between align-item-center'>
                <div className="table-responsive table-borderless">
                    <div className='outreach-details d-flex align-items-center py-2 text-center' style={{ width: "fit-content" }}>
                        <div className='cell'>
                            <p>Added</p>
                            <p>{(data.leads && data.leads > 0) ? data.leads : 0}</p>
                        </div>
                        <div className='cell'>
                            <p>Contacted</p>
                            <p>{data.contacted}</p>
                        </div>
                        <div className='cell'>
                            <p>Replied</p>
                            <p>{data.replied}</p>
                        </div>
                        <div className='cell'>
                            <p>Leads</p>
                            <p>{data.lead}</p>
                        </div>
                        <div className='cell'>
                            <p>Meetings</p>
                            <p>{data.meeting}</p>
                        </div>
                        <div className='cell'>
                            <p>Deals</p>
                            <p>{data.deal}</p>
                        </div>
                    </div>
                </div>
                <div className='outreach-summary d-flex align-items-center'>
                    <div className='cell d-flex align-items-center px-2 py-1 me-2'>
                        <p className='me-2'>{data.accepted}%</p>
                        <p>Accepted</p>
                    </div>
                    <div className='cell d-flex align-items-center px-2 py-1'>
                        <p className='me-2'>{data.rejected}%</p>
                        <p>Rejected</p>
                    </div>
                </div>
            </div>
            <div className='outreach-foot d-flex justify-content-between mt-3 pt-3'>
                <div className='created-by d-flex align-items-center'>
                    <img src={data.createdBy.image} alt='' />
                    <div className='ms-3'>
                        <p>created by</p>
                        <p className='fw-600'>{data.createdBy.name}</p>
                    </div>
                </div>
                {
                    data.topPerformer ?
                        <p className='outreach-badge mb-0 p-1 fw-600'>Top Performer</p> :
                        ''
                }
            </div>
        </div>
    );
}