import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { NavLink, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode'
import { AuthContext } from '../../context/authContext';

export default function LoginContent() {
    const [validated, setValidated] = useState(false);
    const [formValues, setFormValues] = useState({
        'email': '',
        'password': '',
    })
    const naviagte = useNavigate();

    const { setUser } = useContext(AuthContext);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const { isValid, errors } = validate(formValues);

        if (!isValid) {
            toast.error(errors[0]);
        } else {
            const id = toast.loading("Please wait...")
            try {
                const response = await axios.post(`${process.env.REACT_APP_LOCAL_BASE_URL}/api/login/`, formValues);
                toast.success('Login successful', {
                    id: id,
                });
                let decodedToken = jwt(response.data.access_token);
                console.log(decodedToken);

                const response2 = await axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/api/profiles/`, {
                    headers: {
                        Authorization: `Bearer ${response.data.access_token}`
                    }
                })
                setUser(response2.data[0])
                localStorage.setItem('accessToken', JSON.stringify(response.data.access_token));
                naviagte('/dashboard');
            } catch (error) {
                toast.error(error.response.data.error, {
                    id: id,
                });
                console.log(error);
            }
            setValidated(true);
        }
    };

    const validate = (values) => {
        const errors = [];
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        let isValid = true;
        if (!values.email) {
            errors.push("Email is Required");
            isValid = false;
        } else if (!regex.test(values.email)) {
            errors.push("Please enter valid email");
            isValid = false;
        }
        if (!values.password) {
            errors.push("Password is Required");
            isValid = false;
        }
        return { isValid, errors };
    };

    return (
        <div className='login-content'>
            <h2 className='text-center mb-4'>Welcome to Newson</h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='mb-3 mt-3'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type='email'
                        name='email'
                        placeholder='Enter Email Address'
                        required
                        onChange={(e) => setFormValues({ ...formValues, 'email': e.target.value })}
                        value={formValues.email}
                    />
                    <Form.Control.Feedback type="invalid">
                        Invalid Email.
                    </Form.Control.Feedback>
                </div>
                <div className='mb-3'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type='password'
                        name='password'
                        placeholder='Enter Password'
                        required
                        autoComplete='false'
                        onChange={(e) => setFormValues({ ...formValues, 'password': e.target.value })}
                        value={formValues.password}
                    />
                    <Form.Control.Feedback type="invalid">
                        Invalid password.
                    </Form.Control.Feedback>
                    <div className='text-end mt-2'>
                        <NavLink to={'/forgot-pass'} className='colored-link'>Forgot Password?</NavLink>
                    </div>
                </div>
                <Button type='submit' variant='primary w-100'>
                    Login
                </Button>
                <p className='mt-3 text-center'>Don't have an account?
                    <NavLink to={'/signup'} className='colored-link'> Signup</NavLink>
                </p>
            </Form>
        </div>
    )
}
