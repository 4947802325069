import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Tabs, Tab, Form, Modal } from 'react-bootstrap';
import { AiOutlinePlus } from "react-icons/ai";
import { MdFormatListBulleted, MdApps } from "react-icons/md";
import Searchbar from '../Inputs/Searchbar';
import OutreachBox from './OutreachBox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../UI/Loader';

export default function OutreachContent() {
    const [listView, setListView] = useState(true);
    const [outreachData, setOutreachData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const navigate = useNavigate();

    const selectedForDelete = (data) => {
        setIsDeleteModalOpen(true);
        setDeleteId(data.encrypted_id);
    };

    const deleteCampaign = async () => {
        setIsLoading(true);

        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_BASE_URL}/api/outreaches/${deleteId}`)
                .then((response) => {
                    toast.success('campaign deleted successfully');
                    setDeleteId(null);
                    fetchCampaigns();
                    setIsDeleteModalOpen(false);
                    setIsLoading(false);
                })
                .catch((error) => {
                    toast.error('Something went wrong');
                    setIsLoading(false);
                });
        } catch (error) {
            toast.error('Something went wrong');
            setIsLoading(false);
        }
    };

    const fetchCampaigns = async () => {
        try {
            axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/api/outreaches/`)
                .then((response) => {

                    const modifiedData = response.data.map(item => {
                        return {
                            ...item,
                            status: true,
                            progress: '0',
                            createdAt: '2 hours ago',
                            added: '0',
                            contacted: '0',
                            replied: '0',
                            lead: '0',
                            meeting: '0',
                            deal: '0',
                            accepted: '0',
                            rejected: '0',
                            createdBy: {
                                image: '/images/testimonee.jpg',
                                name: 'Bob Marley'
                            },
                            topPerformer: true,
                            members: [
                                {
                                    id: '1',
                                    image: '/images/testimonee.jpg'
                                },
                                {
                                    id: '2',
                                    image: '/images/testimonee.jpg'
                                },
                                {
                                    id: '3',
                                    image: '/images/testimonee.jpg'
                                },
                                {
                                    id: '4',
                                    image: '/images/testimonee.jpg'
                                }
                            ]
                        };
                    });

                    setOutreachData(modifiedData);
                })
                .catch((error) => {
                    /* console.error('Error fetching data:', error); */
                });
        } catch (error) {
            toast.error('Something went wrong');
        }
    };

    useEffect(() => {
        fetchCampaigns();
    }, []);

    const outreach = [
        {
            id: '1',
            status: true,
            title: 'Camp Title 1',
            progress: '0',
            createdAt: '2 hours ago',
            steps: '3',
            added: '0',
            contacted: '0',
            replied: '0',
            lead: '0',
            meeting: '0',
            deal: '0',
            accepted: '0',
            rejected: '0',
            createdBy: {
                image: '/images/testimonee.jpg',
                name: 'Bob Marley'
            },
            topPerformer: true,
            members: [
                {
                    id: '1',
                    image: '/images/testimonee.jpg'
                },
                {
                    id: '2',
                    image: '/images/testimonee.jpg'
                },
                {
                    id: '3',
                    image: '/images/testimonee.jpg'
                },
                {
                    id: '4',
                    image: '/images/testimonee.jpg'
                }
            ]
        },
        {
            id: '2',
            status: true,
            title: 'Camp Title 2',
            progress: '0',
            createdAt: '2 hours ago',
            steps: '3',
            added: '0',
            contacted: '0',
            replied: '0',
            lead: '0',
            meeting: '0',
            deal: '0',
            accepted: '0',
            rejected: '0',
            createdBy: {
                image: '/images/testimonee.jpg',
                name: 'Bob Marley'
            },
            topPerformer: false,
            members: [
                {
                    id: '1',
                    image: '/images/testimonee.jpg'
                },
                {
                    id: '2',
                    image: '/images/testimonee.jpg'
                },
                {
                    id: '3',
                    image: '/images/testimonee.jpg'
                },
                {
                    id: '4',
                    image: '/images/testimonee.jpg'
                }
            ]
        },
        {
            id: '3',
            status: false,
            title: 'Camp Title 3',
            progress: '0',
            createdAt: '2 hours ago',
            steps: '3',
            added: '0',
            contacted: '0',
            replied: '0',
            lead: '0',
            meeting: '0',
            deal: '0',
            accepted: '0',
            rejected: '0',
            createdBy: {
                image: '/images/testimonee.jpg',
                name: 'Bob Marley'
            },
            topPerformer: false,
            members: [
                {
                    id: '1',
                    image: '/images/testimonee.jpg'
                },
                {
                    id: '2',
                    image: '/images/testimonee.jpg'
                },
                {
                    id: '3',
                    image: '/images/testimonee.jpg'
                },
                {
                    id: '4',
                    image: '/images/testimonee.jpg'
                }
            ]
        }
    ];

    const orderby = [
        {
            id: '1',
            value: 'most-recent',
            label: 'Most Recent'
        },
        {
            id: '2',
            value: 'top-performer',
            label: 'Top Performer'
        },
        {
            id: '3',
            value: 'low-performer',
            label: 'Low Performer'
        }
    ];

    const folders = [
        {
            id: '1',
            value: 'all',
            label: 'All'
        },
        {
            id: '2',
            value: 'folder-1',
            label: 'Folder 1'
        },
        {
            id: '3',
            value: 'folder-2',
            label: 'Folder 2'
        }
    ];

    return (
        <div>
            {isLoading ?
                <Loader /> :
                <>
                    <div className='outreach-content'>
                        <Modal
                            show={isDeleteModalOpen}
                            onHide={() => setIsDeleteModalOpen(false)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Campaign</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure to delete this campaign?</Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => setIsDeleteModalOpen(false)}
                                >
                                    Close
                                </Button>
                                <Button variant="danger" onClick={deleteCampaign}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div className='row g-0'>
                            <div className='col-md-12'>
                                <div className='content-top d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap gap-2'>
                                    <Searchbar />
                                    <div className='d-flex justify-content-start justify-content-md-end pe-0 pe-md-3'>
                                        <Form.Select>
                                            {
                                                orderby.map((item) => {
                                                    return <option key={item.id} value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </Form.Select>
                                        <Form.Select>
                                            {
                                                folders.map((item) => {
                                                    return <option key={item.id} value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </Form.Select>
                                        <Button variant='primary'>
                                            <span className='d-none d-md-inline'>New Folder</span>
                                            <AiOutlinePlus size={18} />
                                        </Button>
                                    </div>
                                </div>
                                <div className='breadcrumbs mt-3'>
                                    <p>Outreach &gt; All</p>
                                </div>
                                <div className='content-section secondary-bg p-3'>
                                    <div className='outreach-btns d-flex justify-content-end'>
                                        <Button variant='toggle' className={listView ? 'active me-2' : 'me-2'} onClick={() => setListView(true)}>
                                            <MdFormatListBulleted size={18} />
                                        </Button>
                                        <Button variant='toggle' className={`${!listView ? 'active me-2' : 'me-2'} d-none d-md-inline`} onClick={() => setListView(false)}>
                                            <MdApps size={18} />
                                        </Button>
                                        <Button variant='primary' onClick={() => navigate('/outreach/create')}>
                                            <span className='d-none d-md-inline'>Add New</span>
                                            <AiOutlinePlus size={18} />
                                        </Button>
                                    </div>
                                    <Tabs
                                        defaultActiveKey="my-outreach"
                                        id="outreach-tabs"
                                        className="custom-tabs pb-3"
                                    >
                                        <Tab eventKey="my-outreach" title="My Outreach">
                                            {
                                                listView ?
                                                    outreachData.map((item) => {
                                                        return <OutreachBox key={item.id} data={item} listView={listView} selectedForDelete={selectedForDelete} />
                                                    }) :
                                                    <div className='d-flex flex-wrap'>
                                                        {
                                                            outreachData.map((item) => {
                                                                return <OutreachBox key={item.id} data={item} listView={listView} selectedForDelete={selectedForDelete} />
                                                            })
                                                        }
                                                    </div>
                                            }
                                        </Tab>
                                        <Tab eventKey="all-outreach" title="All">
                                            {
                                                listView ?
                                                    outreachData.map((item) => {
                                                        return <OutreachBox key={item.id} data={item} listView={listView} selectedForDelete={selectedForDelete} />
                                                    }) :
                                                    <div className='d-flex flex-wrap'>
                                                        {
                                                            outreachData.map((item) => {
                                                                return <OutreachBox key={item.id} data={item} listView={listView} selectedForDelete={selectedForDelete} />
                                                            })
                                                        }
                                                    </div>
                                            }
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        <ToastContainer theme="colored" />
                    </div>
                </>
            }
        </div>
    )
}
