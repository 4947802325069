import React from 'react';
import FullWidthLayout from '../Layouts/FullWidthLayout';
import ViewOutreachContent from '../Components/Outreach/ViewOutreachContent';

export default function ViewOutreach() {
  return (
    <FullWidthLayout title={'View Outreach'}>
        <ViewOutreachContent />
    </FullWidthLayout>
  )
}
